/**
 * @author 小熊
 * @description H5与APP交互api
 **/

/**
 * 使用说明：
 * 调用：
 *      bridgeApp[fn](options).then(e => {逻辑处理})
 * 参数说明：
 * @param {options -> params}       传给app参数(Object类型)
 * @param {options -> hasGetData}   是否获取app数据，默认false
 * @param {options -> name}         触发app内方法名
 *
 * @event
 * @param {event} getApp  获取是否在app内
 * @param {event} bridge  与app交互
 **/

 class Fn {
    getApp() {
        // 终端判断
        const u = window.navigator.userAgent;
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;

        const userAgent = u.toLowerCase();
        const isApp = userAgent.includes('ios_yfn') || userAgent.includes('android_yfn') || false;
        return {
            u,
            isiOS,
            isAndroid,
            isApp,
        }
    }
    bridge(options) {
        return new Promise((resolve, reject) => {
            options.params ?? (options.params = '')
            options.hasGetData ?? (options.hasGetData = false)

            // 终端判断
            const { isiOS, isAndroid, isApp, } = this.getApp()
            let result = ''
            if(!options.name) return
            // 调用ios方法
            if(isiOS && isApp) {
                // 方法处理
                result = window.webkit.messageHandlers[options.name].postMessage(options.params)
                // 回调数据处理
                if(options.hasGetData) {
                        window[options.name] = (data) => {
                            result = (data && JSON.parse(data))
                            resolve(result)
                        }
                } else {
                    resolve(result)
                }
            }
            // 调用android方法
            if(isAndroid && isApp) {
                // 方法处理
                if(options.params) {
                    result = window.android[options.name](JSON.stringify(options.params))
                } else {
                    result = window.android[options.name]()
                }
                // 回调数据处理
                if(options.hasGetData) {
                    result = (result && JSON.parse(result))
                }
                resolve(result)
            }
        })
    }
}
export default Fn