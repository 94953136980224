
const base64Encode = (str) => {
    const encodeChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    let result = '', i = 0, len = str.length;
    let r1, r2, r3;
    while (i < len) {
        r1 = str.charCodeAt(i++) & 0xff;
        if (i === len) {
            result += encodeChars.charAt(r1 >> 2);
            result += encodeChars.charAt((r1 & 0x3) << 4);
            result += "==";
            break;
        }
        r2 = str.charCodeAt(i++);
        if (i === len) {
            result += encodeChars.charAt(r1 >> 2);
            result += encodeChars.charAt(((r1 & 0x3)<< 4) | ((r2 & 0xF0) >> 4));
            result += encodeChars.charAt((r2 & 0xF) << 2);
            result += "=";
            break;
        }
        r3 = str.charCodeAt(i++);
        result += encodeChars.charAt(r1 >> 2);
        result += encodeChars.charAt(((r1 & 0x3) << 4) | ((r2 & 0xF0) >> 4));
        result += encodeChars.charAt(((r2 & 0xF) << 2) | ((r3 & 0xC0) >> 6));
        result += encodeChars.charAt(r3 & 0x3F);
    }
    return result;
}
const toImgUrl = (url) => {
    const pattern = /[\u4E00-\u9FFF\u3400-\u4DFF]+/;
    //URL中不包括中文，原样返回
    if(!url || !url.includes('http') || !pattern.test(url)) return url
    //只获取URI部分
    const length = url.length
    const sIndex = url.indexOf("?x-oss")
    const index = sIndex == -1 ? length : sIndex
    const v = url.slice(0, index)
    const next = url.slice(index, length)
    let uri = v.substring(url.indexOf("/", 8))
    uri = uri.split("?")[0];
    //编码
    uri = base64Encode(encodeURIComponent(uri))
    //替换掉特殊字符
    uri = uri.replaceAll("+", "-").replaceAll("/", "_").replaceAll("=", "~")
    const rUrl = `https://cdn2.selleroa.com/image/jewelry/${uri}/jewelry.jpg${next}`;
    return rUrl
}

export default toImgUrl