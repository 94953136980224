/**
 * @author 小熊 
 * @description 上拉加载
 **/ 
/**
 * 使用说明：
 * 调用：
 *     const pullRefresh = new PullRefresh()
 *     pullRefresh.load(callback, options)
 * 参数说明：
* @callback                // 回调函数,eg: (e) => { // 逻辑处理}
* @options -> hasLoadMore  // 是否是触底加载更多类型，反之监听元素是否在可视区域；默认 true 
* @options -> hasUnObserve // 成功监听后是否去除监听，默认false
* @options -> distance     // 距离底部多少距离开始监听，默认 0（eg：1px、1rem、1vw...）
* @options -> unit         // distance单位，默认 rem
* @options -> target       // 被观察目标元素[标签、class、id]；触底加载更多时， 默认使用虚拟生成的元素
* @options -> root         // 目标元素的父元素，默认body
**/
class Fn {
    // 上拉加载
    load(callback, options = {}) {
        options.distance = options.distance || 0
        // options.unit = options.unit || 'rem'
        // 虚拟元素生成
        options.target = options.target || this.createLoadMore(options)
        // 监听
        let observe = new IntersectionObserver((entries, self) => {
          if(options.hasUnObserve) {
            entries.forEach(item => {
              item.isIntersecting && observe.unobserve(item.target)
            })
          }
          callback(entries[0], entries)
        })
        let totalTarget = [...document.querySelectorAll(options.target)]
        totalTarget.forEach(item => {
          observe.observe(item)
        })
        // observe.observe(document.querySelector(options.target))
    }
    createLoadMore(options) {
      let ele = document.createElement('div'), appointEle = options.root && document.querySelector(options.root);
      ele.setAttribute('style', `width: 100%;height: 1px;opacity:0;margin-top: -${options.distance};`) // 兼容absolute布局页面
      ele.setAttribute('class', 'observer-loadMore')
      if(appointEle) {
        appointEle.appendChild(ele)
      } else {
        document.body.appendChild(ele)
      }
      return '.observer-loadMore'
    }
}

export default Fn