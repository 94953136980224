/**
 * @author 小熊
 * @description 广告跳转
 **/

/**
 * 参数说明：
 * @params {jumpType} 跳转类型
 * @params {jumpUrl}  跳转链接[必]
 **/
 class Fn {
    link(options) {
        let href = options.jumpUrl
        if(href) window.location.href = href
    }
}
export default Fn