/**
 * @author 小熊
 * @description spm埋点
**/

/**
 * 使用说明：v-spm="{c: '', d: '', g: '', ...}"
 * 参数说明：
 * @params {c} 区块id，不传默认 1，[注：公共-> 102 首次访问 99 列表 98 加购 97 头部 96 底部 95 底部注册 94 列表侧边注册 93 粘性布局中的排序 92 sku点击 91 商品详情]
 * @params {d} 点位id，不传默认 1
 * @params {g} 自定义传输数据，不传默认使用 v-spm 内的 algo、productCode、categoryCode
**/
import SpmCode from '/utils/common/spmCode'
const spmCode = new SpmCode();

class Fn {
    constructor() {
        this.t = null
        this.pt = Date.now()
        this.startTime = new Date().getTime();
    }
    initial(options, ctx) {
        this.ctx = ctx
        this.options = options
        this.params = {}
        this.debounce = 10
        this.setA()
        this.setB()
        this.setCD()
        this.setE()
        this.setG()
        this.setH()
        this.setDebounce()
    }
    setA() {
        this.params.a = 1
    }
    setB() {
        const rsRoute = this.ctx.$route || {};
        this.params.b = spmCode.initial(rsRoute.name || '') || 99
    }
    setCD() {
        const { c, d } = this.options;
        this.params.c = c || 1
        this.params.d = d || 1
    }
    setE() {
        const nowTime = new Date().getTime();
        this.params.e = `${this.startTime}.${nowTime}`
    }
    setG() {
        let gObj = {};
        const rsRoute = this.ctx.$route || {};
        const query = { ...rsRoute.params, ...rsRoute.query };
        const { g, algo, productCode, categoryCode } = this.options;
        const openTest = this.ctx.$storage.get('biz/openTest')
        if (g) {
            gObj = g
        } else {
            gObj = {}
            algo && (gObj.algo = algo)
            productCode && (gObj.productCode = productCode)
            categoryCode && (gObj.categoryCode = categoryCode)
        }
        !gObj.pageName && (gObj.pageName = rsRoute.name || 'other')
        rsRoute.name.includes('CategoryDetail') && (gObj.pageName = 'category')
        rsRoute.name.includes('Activity') && (gObj.pageName = 'activity')

        gObj.pageId = g?.pageId || query.code || query.activityId || query.keyword || ''
        gObj.productCode && (this.debounce = 200)
        gObj.test = openTest ? 1 : 0
        this.params.g = window.btoa(encodeURI(JSON.stringify(gObj)))
    }
    setH() {
        this.params.h = 1.1
    }
    setMergeSpm() {
        this.params.spm = ''
        const keyObj = {
            a: this.params.a,
            b: this.params.b,
            c: this.params.c,
            d: this.params.d,
            e: this.params.e,
            g: this.params.g,
            h: this.params.h,
        };
        let keyMergeArr = [];
        Object.keys(keyObj).forEach(key => {
            const keyAttr = `${key}${keyObj[key]}`;
            keyMergeArr.push(keyAttr)
        })
        this.params.spm = keyMergeArr.join('-')
    }
    setDebounce() {
        this.debounceFn(() => {
            this.setMergeSpm()
            this.sendSpm()
        }, this.debounce)
    }
    sendSpm() {
        this.ctx.$api.mixins.setSpmData({
            data: JSON.stringify(this.params.spm)
        }).then(response => { })
    }
    debounceFn(fn, delay = 300) {
        this.t && clearTimeout(this.t)
        this.t = setTimeout(() => {
            fn.apply(this, arguments)
        }, delay)
    }
}

export default Fn