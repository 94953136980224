/**
 * @author 小熊
 * @description 获取地址栏参数
 **/
/**
 * 使用说明：
 * this.$query
 */
 class Fn {
    initial(options, app) {
        this.app = app || {}
        const params = this.app.route.params;
        const query = this.app.route.query;
        this.query = {...params, ...query}
        return this.query
    }
}

export default Fn