/**
 * @author 小熊
 * @description copy复制
 **/

/**
 * 参数说明：
 * @params {val}          复制文案
 **/
 class Fn {
    initial(val) {
        if(navigator?.clipboard?.writeText) {
            const copyToClipboard = (text) => navigator.clipboard.writeText(text);
            copyToClipboard(val)
        } else {
            // 创建复制元素
            let textareaElement = document.createElement('textarea');
            textareaElement.setAttribute('readonly', 'readonly');   // 防止弹出输入编辑
            textareaElement.value = val;                            // 将需要拷贝的内容设置到textarea元素中。
            document.body.appendChild(textareaElement );
            textareaElement.select();                               // 选中textarea元素，以备调用系统copy函数复制其中的内容。
            // 文本复制
            let result = document.execCommand('copy');              // 调用操作系统复制api，返回是否复制成功。true或false！
            document.body.removeChild(textareaElement);
            return result
        }
    }
}
export default Fn