/*
* @description 业务 store
*/
export const state = () => ({
    hasRefresh: true, // 是否刷新页面
    hasAddTri: false, // 是否添加过三方埋点
    referrer: '', // 上一页
})

export const mutations = {
    // 是否刷新页面
    setRefresh(state, data) {
        state.hasRefresh = data ?? true
    },
    // 是否添加过三方埋点
    setAddTri(state, data) {
        state.hasAddTri = data ?? false
    },
    // 获取上一页
    setReferrer(state, data) {
        state.referrer = data ?? ''
    }
}

export const actions = {}

export const getters = {}