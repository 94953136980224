/**
 * @author 小熊
 * @description 滚动至指定位置/元素
 **/

/**
 * 参数说明：
 * @params {true}         页面置顶
 * @params {false}        页面置底
 * @params {ele}          滚动容器 eg：id、class、tag，默认 body
 * @params {behavior}     过度动画 'auto'、'smooth'、'instant'，默认 auto
 * @params {block}        垂直方向滚动 'start'、'center'、'end'、'nearest'，默认 start
 * @params {behavior}     水平方向滚动 'start'、'center'、'end'、'nearest'，默认 nearest
 **/
 class Fn {
    initial(options = true) {
        let oObj = '';
        if(Object.prototype.toString.call(options) == '[object Boolean]') {
            oObj = options
        } else {
            oObj = {
                behavior: options.behavior,
                block: options.block,
                inline: options.inline,
            }
        }

        const ele = document.querySelector(options.ele || 'body');
        ele.scrollIntoView(oObj)
    }
}
export default Fn