/**
 * @author 小熊
 * @description 获取语言
 **/
/**
 * 使用说明：
 * this.$language
 */
 class Fn {
    initial(options, app) {
        this.app = app || {}
        this.options = options || {}

        const storeLang = this.app.store.state.national.language || {};
        const language = this.app.$cookies.get('language') || storeLang;
        return language
    }
}

export default Fn