/**
 * @author 小熊
 * @description 设置营销渠道
*/
class Channel{
    // 渠道来源
    ditch(app) {
        this.app = app
        const query = this.app.$query || {};
        let s = this.app.$storage.get('marketChannel'), // 缓存数据
            q = {
                p: query.p,
                c: query.c
            }, // 地址栏数据
            isEqual = false;
        // 三方渠道
        // if(!q.p && query?.ref && (!['m.yfn.com', 'www.yfn.com'].includes(query.ref))) {
        if(!q.p && query.ref && (!['m.yfn.com', 'www.yfn.com'].includes(query.ref))) {
            q.p = query.ref
            !q.c && (q.c = '--')
        }

        s = s || {p: '--', c: '--'}
        !Object.keys(q).length ? q = s : isEqual = q.p != s.p || q.c != s.c
        q.p = String(q.p)
        q.c = String(q.c)
        q.p.includes('ref') && (q.p = q.p.replace('$ref', ''))
        q.c.includes('ref') && (q.c = q.c.replace('$ref', ''))
        q.p === 'undefined' && (q.p = '--')
        q.c === 'undefined' && (q.c = '--')
        // 渠道数据变更重新缓存
        isEqual && this.app.$storage.set('marketChannel', q)

        return {
            pid: q.p,
            pidChannel: q.c
        }
    }
    utm(app) {
        this.app = app
        // utm渠道
        const query = this.app.$query || {};
        let storageUtm = this.app.$storage.get('marketUtm') || {},
            utm = {},
            strUrm = '';
        if(query.utm_id || query.utm_source || query.utm_campaign) {
            utm.utm_id = query.utm_id || (storageUtm.utm_id || '')
            utm.utm_source = query.utm_source || (storageUtm.utm_source || '')
            utm.utm_medium = query.utm_medium || (storageUtm.utm_medium || '')
            utm.utm_campaign = query.utm_campaign || (storageUtm.utm_campaign || '')
            utm.utm_term = query.utm_term || (storageUtm.utm_term || '')
            utm.utm_content = query.utm_content || (storageUtm.utm_content || '')
        } else {
            utm = storageUtm
        }
        const toType = Object.prototype.toString;
        if(Object.keys(utm).length > 0) {
            Object.keys(utm).forEach(p => {
                if(toType.call(utm[p]) == '[object Array]') {
                    utm[p] = utm[p][0]
                }
            })
            strUrm = `id_${utm.utm_id}-source_${utm.utm_source}-medium_${utm.utm_medium}-campaign_${utm.utm_campaign}-term_${utm.utm_term}-content_${utm.utm_content}`
            this.app.$storage.set('marketUtm', utm)
        }
        return {
            ...utm,
            strUrm
        }
    }
}

export default Channel