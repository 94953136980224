/**
 * @description 深拷贝
 *
 * @use 无需引入直接使用
 *      eg：this.$fnApi.deepClone()
 *
 * @params 参数说明:
 * @params {v}   拷贝数据, Array、Object
*/
const deepClone = (v) => {
    const checkType = (data) => {
      let type = Object.prototype.toString.call(data);
      type = type.slice(8, -1);
      return type;
    };
    const targetType = checkType(v);
    let result;
    if (targetType === 'Object') {
      result = {};
    } else if (targetType === 'Array') {
      result = [];
    } else {
      return v;
    }
    for(let i in v) {
      let value = v[i];
      const valueType = checkType(value);
      if (valueType === 'Object' || valueType === 'Array') {
        result[i] = deepClone(value);
      } else {
        result[i] = value;
      }
    }
    return result;
  }
  export default deepClone;