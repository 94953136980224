/**
 * @author 小熊
 * @description 防抖
 **/

/**
 * 参数说明：
 * @params {fn}         事件
 * @params {delay}      执行时间，防抖->300、节流->1000
 * @events {debounce}   防抖
 * @events {throttle}   节流
 * @use                 debounce(fn)/throttle(fn)
 **/
 class Fn {
    constructor() {
        this.t = null
        this.pt = Date.now()
    }
    debounce(fn, delay = 300) {
        this.t && clearTimeout(this.t)
        this.t = setTimeout(() => {
            fn.apply(this, arguments)
        }, delay)
    }
    throttle(fn, delay = 1000) {
        const nt = Date.now();
        if(nt - this.pt >= delay) {
            this.pt = Date.now()
            fn.apply(this, arguments)
        }
    }
}

export default Fn