/**
 * @author 小熊
 * @description 获取国家
 **/
/**
 * 使用说明：
 * this.$country
 */
class Fn {
    initial(options, app) {
        this.app = app || {}
        this.options = options || {}
        const storeCountry = this.app.store.state.national.country;
        const country = this.app.$cookies.get('country') || storeCountry || {};
        return country
    }
}

export default Fn