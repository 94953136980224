/**
 * @author 小熊
 * @description 缓存获取/设置/清除
 **/
/**
 * 使用说明：
 * this.$storage[get/set/remove/clear]
 * 示例：
 * 获取缓存:      this.$storage.get('country')
 * 获取所有缓存:  this.$storage.getAll()
 * 设置缓存:      this.$storage.set('country', 缓存数据, [bool值，是否 cookies 也设置])
 * 移除缓存:      this.$storage.remove('country')
 * 清除缓存:      this.$storage.clear()
 */
 class Fn {
    initial(v, app) {
        return {
            get: (v) => {
                return this.get(v, app)
            },
            getAll: (v) => {
                return this.getAll(v, app)
            },
            set: (v, c, bool) => {
                this.set(v, c, bool, app)
            },
            remove: (v, bool) => {
                this.remove(v, bool, app)
            },
            clear: (v) => {
                this.clear(v, app)
            }
        }
    }
    /* 获取指定缓存 */
    get(key, app) {
        if(!key) return ''
        let r = '';
        if(process.client) {
            r = localStorage.getItem(key)
            try {
                r = JSON.parse(r)
            } catch {}
            !r && (r = app.$cookies.get(key) || '')
        } else {
            r = app.$cookies.get(key) || ''
        }
        return r || ''
    }
    /* 获取所有缓存 */
    getAll(key, app) {
        let r = {};
        if(process.client) {
            const l = localStorage || {};
            Object.keys(l).forEach(key => {
                try {
                    r[key] = JSON.parse(l[key]);
                } catch {
                    r[key] = l[key];
                }
            })
        } else {
            r = app.$cookies.getAll()
        }
        return r || {}
    }
    /* 设置缓存 */
    set(key, data, bool, app) {
        if(!key || !data) return
        if(process.client) {
            localStorage.setItem(key, JSON.stringify(data));
        }
        if(bool) {
            app.$cookies.set(key, data)
        }
    }
    /* 移除缓存 */
    remove(key, bool = true, app) {
        if(!key) return
        if(process.client) {
            localStorage.removeItem(key);
        }
        if(bool) {
            app.$cookies.remove(key)
        }
    }
    /* 清除缓存 */
    clear(key, app) {
        if(process.client) {
            localStorage.clear()
        }
        app.$cookies.removeAll()
    }
}

export default Fn