/**
 * @author 小熊
 * @description dst脱敏
 **/

/**
 * 参数说明：
 * @params {name}          脱敏文案
 * @params {ellipsis}      脱敏符号，默认 ****
 **/
 class Fn {
    initial(name, ellipsis = '****') {
        if (name && name != undefined && name != '') {
            if (name.length == 2) {
                name = name.substring(0, 1) + ellipsis
            }
            if (name.length > 2) {
                name = name.substring(0, 1) + ellipsis + name.charAt(name.length - 1)
            }
            return name
        } else {
            return name
        }
    }
}
export default Fn