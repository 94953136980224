/**
 * @author xiaoxiong
 * @description 通用方法 
 **/ 
class Fn {
  // 多层级数据查找----------------------------------------------
  levelDataFind(obj) {
    let result = {},
      level = {};

    result[obj.childKey] = obj.list
    obj.target.forEach((item, index) => {
      result = this.forEachFn({
        key: obj.key,
        value: item,
        list: result[obj.childKey]
      })
      level[`parent${index+1}`] = item
    })
    return {
      ...result,
      ...level
    }
  }
  forEachFn(obj) {
    let result = {}
    obj.list.forEach(item => {
      if (item[obj.key] == obj.value) {
        result = {
          ...item
        }
      }
    })
    return result
  }
  // 上下轮播封装 ----------------------------------------------
  scrollEvent(obj, callback) {
    let container = $(obj.container),
        target = $(obj.target);
    if(!container) return
    let targetAll = container.find(obj.target);
    let i = 0, length = targetAll.length, animateScroll = '', speed = obj.speed || 3000;

    // 初始化css
    container.css({
      position: 'relative',
      width: '100%',
      height: '100%'
    })
    targetAll.css({
      position: 'absolute',
      display: 'none'
    })
    target.eq(0).show()

    if (!length) return

    // animate 滚动
    animateScroll = setInterval(() => {
      i += 1
      if (i > length - 1) i = 0
      container.find(obj.target).css({
        top: '100%'
      })
      target.eq(i).show().animate({
        top: '50%'
      }, 'slow').siblings().hide()
      if(callback) {
        callback({
          i: i,
          length: length,
          speed: speed,
          target: target,
          targetAll: targetAll,
          container: container,
          animateScroll: animateScroll,
          endTime: obj.endTime,
        })
      }
    }, speed);

    // endTime 秒后禁止滚动
    if(obj.endTime) {
      setTimeout(() => {
        clearInterval(animateScroll)
      }, obj.endTime)
    }

    return {
      i: i,
      length: length,
      speed: speed,
      target: target,
      targetAll: targetAll,
      container: container,
      animateScroll: animateScroll,
      endTime: obj.endTime,
    }
  }
}

export default Fn
