/**
 * @author 小熊
 * @description 货币金额置换
 **/
/**
 * 使用说明：
 * this.$price(v, c)
 *
 * 参数说明
 * @params {v} 金额
 * @params {c} 货币符号，默认使用缓存
 */
class Fn  {
    initial(options, app) {
        this.options = options
        this.app = app
        this.c_obj = {};
        this.p_obj = {};
        this.c_default = [{
            code: 'EUR',
            symbol: '€'
        }, {
            code: 'RUB',
            symbol: 'pуб.'
        }];
        const r = this.currencyPrice(this.options.price, this.options.currency)
        return r.price 
    }
    getStorageC() {
        const storeCurrency = this.app.store.state.national.currency || {};
        let currency = this.app.$cookies.get('currency') || storeCurrency;
        this.c_obj.symbol = currency.symbol
        this.c_obj.symbolS = currency.symbol
    }
    checkC(v, c) {
        this.c_obj.has_c = false
        c && (this.c_obj.symbol = c)
        this.c_default.forEach(p => {
            if(c) {
                if(p.symbol.includes(c)) {
                    this.c_obj.has_c = true
                    this.c_obj.symbol = p.symbol
                }
            } else {
                if(p.symbol.includes(this.c_obj.symbolS)) {
                    this.c_obj.has_c = true
                    this.c_obj.symbol = p.symbol
                }
            }
        })
    }
    mergePrice() {
        let price = [this.c_obj.symbol, this.p_obj.price];
        this.c_obj.has_c && (price.reverse())
        this.p_obj.merge = price.join('')
    }
    currencyPrice(v, c) {
        let r = {};
        this.getStorageC()
        try {
            this.p_obj.price = v
            this.c_obj.c = c || ''
            this.checkC(v, c)
            this.mergePrice()
            r.price = this.p_obj.merge
        }catch{
            r.price = `${this.c_obj.symbol}${v}`
        }
        return r
    }

}

export default Fn