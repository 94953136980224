/**
 * @author 小熊
 * @description 获取设备信息
**/
import MobileDetect from 'mobile-detect'

class Fn {
    network() {
        let networkType = 'cable network';
        if(process.client) {
            let ua = window.navigator.userAgent;
            let networkStr = ua.match(/NetType\/\w+/) ? ua.match(/NetType\/\w+/)[0] : 'NetType/other';
            networkStr = networkStr.toLowerCase().replace('nettype/', '');
            switch (networkStr) {
                case 'wifi':
                    networkType = 'wifi';
                    break;
                case '4g':
                    networkType = '4g';
                    break;
                case '3g':
                    networkType = '3g';
                    break;
                case '3gnet':
                    networkType = '3g';
                    break;
                case '2g':
                    networkType = '2g';
                    break;
                default:
                    networkType = 'cable network';
            }
        }
        return networkType;
    }
    model() {
        let s = {
            os: 'unknown', // 系统类型
            osVersion: 'unknown', // 系统版本
            mobile: 'unknown', // 终端设备
            browser: 'unknown', // 浏览器
            browserVersion: 'unknown' // 浏览器版本
        }
        const modelData = () => { // 设备
            let UserAgent = window.navigator.userAgent.toLowerCase();
            if (UserAgent.match(/iphone/i) == "iphone") {
                return 'iPhone';
            } else if (UserAgent.match(/ipad/i) == "ipad") {
                return 'iPad';
            }else if (UserAgent.match(/mac/i) == "mac") {
                return 'Mac';
            }else if (UserAgent.match(/huawei/i) == "huawei") {
                return 'Huawei';
            } else if (UserAgent.match(/honor/i) == "honor") {
                return 'Huawei';
            } else if (UserAgent.match(/oppo/i) == "oppo") {
                return 'Oppo';
            }else if(UserAgent.match(/pacm00/i) == "pacm00") {
                return 'Oppo';
            }else if(UserAgent.match(/vivo/i) == "vivo") {
                return 'Vivo';
            } else if (UserAgent.match(/mi\s/i) == "mi ") {
                return 'Xiaomi';
            } else if (UserAgent.match(/mix\s/i) == "mix ") {
                return 'Xiaomi';
            } else if (UserAgent.match(/redmi/i) == "redmi") {
                return 'Xiaomi';
            }else if (UserAgent.match(/sm-/i) == "sm-"){
                return 'Samsung';
            } else if (UserAgent.match(/windows/i) == "windows"){
                return 'Windows';
            } else {
                return '';
            }
        };
        const browserData = () => { // 浏览器
            const UserAgent = window.navigator.userAgent;
            let browser = '';
            if (UserAgent.indexOf("MSIE") >= 0) {
                //ie
                browser = "ie";
            } else if (UserAgent.indexOf("Firefox") >= 0) {
                //firefox
                browser = "Firefox";
            } else if (UserAgent.indexOf("Opera") >= 0 || UserAgent.indexOf("OPR/") >= 0) {
                //Opera
                browser = "Opera";
            } else if (UserAgent.indexOf("Chrome") >= 0) {
                //Chrome
                browser = "Chrome";
            } else if (UserAgent.indexOf("Safari") >= 0) {
                //Safari
                browser = "Safari";
            } else if (UserAgent.indexOf("Netscape") >= 0) {
                //Netscape
                browser = "Netscape";
            }
            return browser
        };
        if(process.client) {
            if(MobileDetect) {
                let md = new MobileDetect(window.navigator.userAgent)
                s.os = md.os() || 'unknown' // 系统类型
                s.mobile = modelData() || md.mobile() || 'Android' // 终端设备
                s.browser = browserData() || md.userAgent() || 'unknown' // 浏览器
                s.browserVersion = md.version(s.browser) || 'unknown' // 浏览器版本
                if(s.os == 'AndroidOS') {
                    s.osVersion = md.version('Android') || 'unknown' // 系统版本
                } else {
                    s.osVersion = md.version(s.os) || 'unknown' // 系统版本
                }
            }
        }
        return s
    }
}

export default Fn