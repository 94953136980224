/**
 * @author 小熊
 * @description 获取货币
 **/
/**
 * 使用说明：
 * this.$currency
 */
class Fn {
    initial(options, app) {
        this.app = app || {}
        this.options = options || {}

        const storeCurrency = this.app.store.state.national.currency || {};
        const currency = this.app.$cookies.get('currency') || storeCurrency;
        return currency
    }
}

export default Fn