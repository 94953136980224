// 获取/设置 商品的code、seoUrl
const getUrl = (e = {}) => {
    const v = e.seoUrl
    const code = e.code
    let obj = {
        code: '',
        seoUrl: ''
    }
    // 解析seoUrl
    const reg = new RegExp('.htm', 'g')
    const seoUrl = v.replace(reg, '') || ''
    if(code) {
        obj.code = code
        // obj.seoUrl = seoUrl
    } else {
        const vArr = v.split('-p')
        const [lastV] = [...vArr].reverse();
        const lastLength = lastV.length
        if(isFinite(lastV) && lastLength == 8) {
            // const seoUrlArr = v.split(`-p${lastV}`)
            obj.code = lastV
            // obj.seoUrl = seoUrlArr[0]
            obj.seoUrl = v
        } else {
            obj.seoUrl = seoUrl
        }
    }
    return obj
}

const setUrl = (e = {}) => {
    const code = e.code || ''
    const seoUrl = e.seoUrl
    let merge = {
        code,
        seoUrl
    }
    // if(code) {
    //     merge.seoUrl = `${seoUrl}-p${code}`
    // }
    return merge
}

export default {
    getUrl,
    setUrl
}