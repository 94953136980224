/**
 * @author 小熊
 * @description 获取页面信息
 **/

/**
 * 参数说明：
 * @events {getScrollPosition}     获取当前滚动条的长度，水平、垂直方向
 * @events {getDomToViewPosition}  获取 dom 到视口左侧和顶部的相对位置
 * @events {getBoard}              获取展示板信息
 **/
class Fn  {
    // 获取当前滚动条的长度，水平、垂直方向
    getScrollPosition () {
        let x, y
        if (!!window.pageXOffset) {
            x = window.pageXOffset
            y = window.pageYOffset
        } else {
            x = (document.documentElement || document.body.parentNode || document.body).scrollLeft
            y = (document.documentElement || document.body.parentNode || document.body).scrollTop
        }
        return {x, y}
    }
    // 获取 dom 到视口左侧和顶部的相对位置
    getDomToViewPosition (options) {
        /**
         * @params {tag}   元素 eg: id、class、tag
        */
        this.options = options || {}
        let dom = document.querySelector(this.options.tag)
        let rectObject = dom.getBoundingClientRect()
        return {
            domToViewLeft: rectObject.left,
            domToViewTop: rectObject.top
        }
    }
    // 获取展示板信息
    getBoard (options) {
        /**
         * @params {tag}   元素 eg: id、class、tag
        */
        this.options = options || {}
        let {x, y} = this.getScrollPosition() // 获取滚动条长度
        let {domToViewLeft, domToViewTop} = this.getDomToViewPosition(options) // 获取到视口的距离
        return {
            scrollX: x, // 滚动条长度
            scrollY: y,
            domClientX: domToViewLeft, // tag距视口距离
            domClientY: domToViewTop,
            domBodyX: domToViewLeft + x, // tag距body顶部距离
            domBodyY: domToViewTop + y
        }
    }
    // 锚点定位
    point(options) {
        options.sub ?? (options.sub = 0)
        const p = this.getBoard(options);
        window.scrollTo(0, p.domBodyY - options.sub)
    }
}

export default Fn