/**
 * @author 小熊
 * @description 公共Api
 *
 * @use this.$fnApi[方法名]
 *
 * @注: params 方法具体使用说明可查看对应的文件
 * @param {common}                通用方法
 * @param {mixData}               通用数据
 * @param {jumpAd}                广告跳转
 * @param {buried}                三方数据埋点
 * @param {spm}                   导购追踪体系
 * @param {pullRefresh}           触底加载更多
 * @param {scrollTo}              容器滚动至页面指定位置
 * @param {copy}                  复制
 * @param {debounce}              防抖
 * @param {throttle}              节流
 * @param {bridgeApp}             app交互
*/
import MixData from '/utils/common/mixData'
import JumpAd from '/utils/common/jumpAd'
import Buried from '/utils/common/buried'
import PullRefresh from '/utils/common/pullRefresh'
import ScrollTo from '/utils/common/scrollTo'
import Position from '/utils/common/position'
import Copy from '/utils/common/copy'
import Save from '/utils/common/save'
import Dst from '/utils/common/dst'
import BridgeApp from '/utils/common/bridgeApp'
import productUrl from '/utils/common/productUrl'
import deepClone from '/utils/common/deepClone'
import Common from '/utils/common/common'

const mixData = new MixData();
const jumpAd = new JumpAd();
const buried = new Buried();
const pullRefresh = new PullRefresh();
const scrollTo = new ScrollTo();
const position = new Position();
const copy = new Copy();
const save = new Save();
const dst = new Dst();
const bridgeApp = new BridgeApp();
const common = new Common();
const {debounce, throttle} = save;

const fn = {
    mixData,
    jumpAd,
    buried,
    pullRefresh,
    scrollTo,
    position,
    copy,
    save,
    dst,
    debounce,
    throttle,
    bridgeApp,
    productUrl,
    deepClone,
    common,
};

export {
    mixData,
    jumpAd,
    buried,
    pullRefresh,
    scrollTo,
    position,
    copy,
    save,
    dst,
    debounce,
    throttle,
    bridgeApp,
    productUrl,
    deepClone,
    common,
};

export default fn;