/**
 * @author 小熊
 * @description 设置页面 seo head
 **/

/**
 * 使用说明:
 * this.$ctxApi.seoHead.initial(options)
 *
 * 参数说明：
 * @params {title}        页面标题
 * @params {keywords}     关键词
 * @params {description}  描述
 * @params {image}        图片
 **/
 class Fn {
    initial(options = {}) {
        this.options = options
        this.options.key ?? (this.options.key = {})
        this.defaultKeyword = 'YFN'
        this.defaultImg = 'https://cdn2.selleroa.com/yfn-upload/review/1690270042834.png'
        this.setKey()
        this.setTitle()
        this.setMergeMeta()
        this.setHead()
        return this.head
    }
    // 键值
    setKey() {
        const each_key = ['title', 'keywords', 'description', 'image'];
        this.r_key = {};
        each_key.forEach(key => {
            const replace_key = this.options.key[key] || key;
            const content = this.options[replace_key]
            this.r_key[key] = content || this.defaultKeyword
        })
        if(this.r_key['image'] == this.defaultKeyword) {
            this.r_key['image'] = this.defaultImg
        }
    }
    // 标题
    setTitle() {
        this.title = this.r_key['title']
    }
    // meta设置
    setPageMeta() {
        this.pageMeta = [
        // {
        //     key: 'name',
        //     keyVal: 'keywords',
        //     content: this.r_key.keywords
        // }, 
        {
            key: 'name',
            keyVal: 'description',
            content: this.r_key.description
        }]
    }
    setOgMeta() {
        // 三方分享
        this.ogMeta = [{
            key: 'property',
            keyVal: 'og:title',
            content: this.title
        }, {
            key: 'property',
            keyVal: 'og:image',
            content: this.r_key.image
        }, {
            key: 'property',
            keyVal: 'og:description',
            content: this.r_key.description
        }, {
            key: 'name',
            keyVal: 'twitter:card',
            content: 'summary_large_image'
        }, {
            key: 'name',
            keyVal: 'twitter:title',
            content: this.title
        }, {
            key: 'name',
            keyVal: 'twitter:description',
            content: this.r_key.description
        }, {
            key: 'name',
            keyVal: 'twitter:image',
            content: this.r_key.image
        }]
    }
    setMergeMeta() {
        this.setPageMeta()
        this.setOgMeta()
        this.meta = [];
        const metaArr = [...this.pageMeta, ...this.ogMeta];
        metaArr.forEach(p => {
            const obj = {
                hid: p.keyVal,
                [p.key]: p.keyVal,
                content: p.content
            };
            this.meta.push(obj)
        })
    }
    setHead() {
        this.head = {};
        this.head = {
            title: this.title,
            meta: this.meta
        }
    }
}

export default Fn