/* 页面编码 */
class Fn {
    initial(key) {
        const keyObj = {
            'Home': 1.1,
            'Category': 1.2,
            'Category-Seo': 1.2,
            'Product': 2.1,
            'Activity-FlashSale': 2.3,
            'Activity-FullFold': 2.4,
            'Activity-FullReduce': 2.5,
            'CategoryDetail': 2.6,
            'CategoryDetail-Seo': 2.6,
            'Search': 2.7,
            'Bag': 3.1,
            'OrderConfirm': 3.2,
            'Mine': 3.3,
            'Activity-FreeGifts': 3.4,
            'Activity-BestSeller': 3.5,
            'Activity-NewComer': 3.6,
            'Address': 3.7,
            'CardPay': 3.8,
            'Login': 3.9,
            'PaySuccess': 4.2,
            'Review-Success': 4.3,
            'PreSearch': 4.4,
            'Template-Index': 4.5,
            'Template-Seo': 4.5
        };
        return keyObj[key] || 99
    }
}

export default Fn