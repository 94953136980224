/*
* @description 通用数据 国家、货币、语言
*/
export const state = () => ({
    country: {},
    currency: {},
    language: {},
    history:null
})

export const mutations = {
    setCountry(state, data) {
        state.country = data || {}
    },
    setCurrency(state, data) {
        state.currency = data || {}
    },
    setLanguage(state, data) {
        state.language = data || {}
    },
    setHistory(state,data){
        state.history = data
        sessionStorage.setItem('history',state.history)
    }
}

export const actions = {}

export const getters = {}