/**
 * @author 小熊
 * @description 获取登录状态
 **/
/**
 * 使用说明：
 * this.$login()
 */
 class Fn {
    initial(options, app) {
        this.app = app || {}
        this.options = options || {}

        let token = false;
        const cooToken = this.app.$cookies.get('token');
        if(process.client) {
            token = !!(localStorage.getItem('token') || cooToken)
        } else {
            token = !!cooToken
        }
        return token || false
    }
}

export default Fn